/* eslint-disable jsx-a11y/media-has-caption */
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import '../../styles/common.scss';
import Modal from 'react-modal';
import Video from '../../videos/dummy-video.mp4';

function BannerVideo() {
  const videoRef = React.useRef();
  const [showPlayBtn, setShowPlayBtn] = React.useState(true);

  const playPause = () => {
    const myVideo = videoRef.current;
    if (myVideo.paused) {
      myVideo.play();
      myVideo.controls = true;
    } else {
      myVideo.pause();
    }
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    setShowPlayBtn(true);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <div className="video-section">
      <StaticImage src="../../images/banner01.jpg" alt="img description" layout="fullWidth" />
      {showPlayBtn && (
        <button className="btn-play" type="button" onClick={openModal}>
          <StaticImage src="../../images/btn-play.svg" alt="img description" />
        </button>
      )}
      <Modal
        className="modal-block"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        // eslint-disable-next-line react/jsx-no-bind
        onAfterOpen={afterOpenModal}
        // eslint-disable-next-line react/jsx-no-bind
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <button className="btn-close" type="button" onClick={closeModal}>
          X
        </button>
        <div className="modal-holder">
          {showPlayBtn && (
            <button className="btn-play" type="button" onClick={playPause}>
              <StaticImage src="../../images/btn-play.svg" alt="img description" />
            </button>
          )}
          <video
            width="420"
            ref={videoRef}
            playsInline
            onPlay={() => {
              setShowPlayBtn(false);
            }}
            onPause={() => {
              setShowPlayBtn(true);
            }}>
            <source
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </Modal>
    </div>
  );
}

export default BannerVideo;
