/* eslint-disable jsx-a11y/media-has-caption */
import clsx from 'clsx';
import * as React from 'react';
import '../../styles/common.scss';

function TwoColumns({ image, heading, children, reverse }) {
  const [showUi, setShowUi] = React.useState(false);
  React.useEffect(() => {
    setShowUi(true);
  }, []);
  return showUi ? (
    <div className={clsx('two-columns', reverse ? 'reverse' : null)}>
      <div className="container">
        <div className="img-box">{image}</div>
        <div className="text-box">
          <h2>{heading}</h2>
          {children}
        </div>
      </div>
    </div>
  ) : null;
}

export default TwoColumns;
