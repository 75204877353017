/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import Modal from 'react-modal';

function SliderBlock() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    setTimeout(() => {
      window.focus();
    }, 10);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const infoSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const sliderData = [
    {
      title: 'Friday Night Live Stream',
      description:
        "Join us this Friday for an exciting live stream where we discuss the latest movies, share gaming tips, and catch up on the week's hottest trends. Don't miss out on the fun!",
    },
    {
      title: 'Cooking Challenge Showdown',
      description:
        'Get ready for a culinary adventure! Join our cooking challenge and watch as talented chefs battle it out to create mouthwatering dishes. Who will be crowned the ultimate cooking champion?',
    },
    {
      title: 'Tech Talk: Gadgets and Gizmos',
      description:
        'Tune in to our tech talk series where we dive deep into the world of gadgets and gizmos. Discover the latest innovations, product reviews, and tech trends that are shaping our future.',
    },
    {
      title: 'Travel Diaries: Exploring the Unknown',
      description:
        'Embark on a virtual journey with us as we explore hidden gems and exotic destinations from around the world. Get inspired for your next adventure!',
    },
    {
      title: 'Music Jam Session',
      description:
        "Join our talented musicians for a jam session that will lift your spirits and get you grooving. From classic tunes to original compositions, there's something for every music lover.",
    },
    {
      title: 'Fitness & Wellness Workshop',
      description:
        'Take a step towards a healthier lifestyle with our fitness and wellness workshop. Learn valuable tips, join interactive workouts, and boost your well-being.',
    },
    {
      title: 'Book Club: Literary Delights',
      description:
        'Calling all bookworms! Our book club meets regularly to discuss captivating reads, share book recommendations, and connect with fellow literature enthusiasts.',
    },
    {
      title: 'Art Gallery Showcase',
      description:
        'Immerse yourself in a world of art and creativity. Explore our virtual art gallery showcasing the works of talented artists from diverse backgrounds.',
    },
    {
      title: 'Pets Corner: Furry Friends Edition',
      description:
        'Get ready for a dose of cuteness! Tune in to our Pets Corner, where adorable pets steal the spotlight. Share your own pet stories and photos with the community.',
    },
    {
      title: 'DIY Home Improvement Tips',
      description:
        "Transform your living space with our DIY home improvement tips. From interior design ideas to renovation projects, we've got you covered.",
    },
  ];
  return (
    <div className="slider-wrap">
      <div className="container">
        <Slider className="slider" {...infoSlider}>
          {sliderData?.map(slide => (
            <div className="slide">
              <div className="box" onClick={openModal}>
                <div className="img-box">
                  <StaticImage src="../../images/img05.jpg" alt="img description" layout="fullWidth" />
                  <button type="button" className="btnPlay">
                    <StaticImage src="../../images/btn-play.svg" alt="img description" />
                  </button>
                </div>
                <div className="text-box">
                  <strong className="title">{slide?.title}</strong>
                  <p>{slide?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Modal
          className="modal-block"
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal">
          <button className="btn-close" type="button" onClick={closeModal}>
            X
          </button>
          <div className="modal-holder">
            <iframe
              src="https://www.youtube.com/embed/yAoLSRbwxL8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default SliderBlock;
